import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticStyle:{"font-family":"Agbalumo","color":"white","font-size":"120%"}},[_c(VRow,[_c(VSpacer),_c(VCol,{staticStyle:{"text-align":"center"},attrs:{"cols":"6"}},[_c('h3',[_vm._v("TABU!")])]),_c(VSpacer)],1),_c(VRow,[_c(VCol,{staticStyle:{"text-align":"center"},attrs:{"md":"3","cols":"6"}},[_c('h4',[_vm._v("Team Blue"),_c('br'),_c('br')]),_c('div',{staticClass:"avatarContainer",staticStyle:{"background-color":"#4fcaff","border-radius":"12px"}},[_c('draggable',{attrs:{"list":_vm.lobby.bluePlayers,"group":_vm.isHost == 1 ? 'players' : null},on:{"change":_vm.teamChanged}},_vm._l((_vm.lobby.bluePlayers),function(player){return _c('div',[_c('h5',[_vm._v(_vm._s(player.Name))]),_c(VAvatar,{staticClass:"userAvatar",class:{ host: player.IsHost == 1 },attrs:{"size":"80"}},[_c('img',{attrs:{"src":player.ImageUrl}})])],1)}),0)],1)]),_c(VSpacer),_c(VCol,{staticStyle:{"text-align":"center"},attrs:{"md":"3","cols":"6"}},[_c('h4',[_vm._v("Team Red"),_c('br'),_c('br')]),_c('div',{staticClass:"avatarContainer",staticStyle:{"background-color":"#ff3f41","border-radius":"12px"}},[_c('draggable',{attrs:{"list":_vm.lobby.redPlayers,"group":_vm.isHost == 1 ? 'players' : null}},_vm._l((_vm.lobby.redPlayers),function(player){return _c('div',[_c('h5',[_vm._v(_vm._s(player.Name))]),_c(VAvatar,{staticClass:"userAvatar",class:{ host: player.IsHost == 1 },attrs:{"size":"80"}},[_c('img',{attrs:{"src":player.ImageUrl}})])],1)}),0)],1)])],1),_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"1"}},[(this.isHost == 1 && !this.canStart)?_c(VBtn,{attrs:{"disabled":""}},[_vm._v(" Spiel starten "),_c('font-awesome-icon',{attrs:{"icon":"play"}})],1):_vm._e(),(this.isHost == 1 && this.canStart)?_c(VBtn,{on:{"click":_vm.startGame}},[_vm._v(" Spiel starten "),_c('font-awesome-icon',{attrs:{"icon":"play"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }