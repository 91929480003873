import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"app"}},[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c(VBtn,{attrs:{"text":"","href":"/"}},[_c('h1',[_vm._v("Tabugame ")])]),_c('h4',[_vm._v("by BrainyXS")])],1),_c(VSpacer),_c(VBtn,{attrs:{"text":"","href":"https://twitch.tv/brainyxs","target":"_blank"}},[_c(VIcon,[_vm._v("mdi-twitch")])],1),_c(VBtn,{attrs:{"text":"","href":"https://github.com/schneidermanuel","target":"_blank"}},[_c(VIcon,[_vm._v("mdi-github")])],1)],1),_c(VSnackbar,{staticStyle:{"margin-bottom":"5%"},attrs:{"id":"snackbar","bottom":"","color":_vm.$store.state.snackbar.color,"timeout":_vm.$store.state.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.$store.state.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Ok")])]}}]),model:{value:(_vm.$store.state.snackbar.show),callback:function ($$v) {_vm.$set(_vm.$store.state.snackbar, "show", $$v)},expression:"$store.state.snackbar.show"}},[_vm._v(" "+_vm._s(_vm.$store.state.snackbar.message)+" ")]),_c(VMain,[(this.$store.state.inited)?_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(!this.$store.state.loading),expression:"!this.$store.state.loading"}]}):_vm._e(),_c('Loader',{directives:[{name:"show",rawName:"v-show",value:(this.$store.state.loading),expression:"this.$store.state.loading"}]})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }